var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{staticClass:"ma-2",style:(`cursor: ${_vm.canClickTools === false ? 'default' : 'pointer'}; background: var(--${
    !_vm.device.status || _vm.diffSecondsActual > _vm.deadPeriodLimit
      ? 'danger'
      : _vm.diffSecondsActual > _vm.periodLimit
      ? 'warning'
      : 'success'
  }); color: #fff;`),on:{"click":function($event){_vm.canClickTools === false ? ()=>{} :
    _vm.$router.push(
      `/Networks/${_vm.$route.params.serviceId}/Tools/Ping?ip=${_vm.device.lastIP}&gateway=${_vm.device.gatewayId}&live=1&run=1`
    )}}},[_vm._v(" Device "+_vm._s(!_vm.device.status || _vm.diffSecondsActual > _vm.periodLimit ? "Offline" : "Online")+" "),_c('span',{staticStyle:{"width":"5px"}}),(_vm.diffSecondsActual <= _vm.periodLimit && _vm.device.status)?_c('span'):(_vm.diffDays < _vm.longTimeAgoDayLimit)?_c('span',[_vm._v(" ("+_vm._s(`${_vm.diffDays > 0 ? `${_vm.diffDays}d ` : ""}` + `${ _vm.diffHours > 0 || _vm.diffDays > 0 ? `${_vm.lessThan(_vm.diffHours, 10) ? "0" : ""}${_vm.diffHours}:` : "" }` + `${ _vm.diffMinutes > 0 || _vm.diffHours > 0 || _vm.diffDays > 0 ? `${_vm.lessThan(_vm.diffMinutes, 10) ? "0" : ""}${_vm.diffMinutes}:` : "" }` + `${`${_vm.lessThan(_vm.diffSeconds, 10) ? "0" : ""}${_vm.diffSeconds}`}`)+_vm._s(_vm.diffSecondsActual >= 60 ? "" : "s")+" ago) ")]):_c('span',[_vm._v("(A long time ago)")])])
}
var staticRenderFns = []

export { render, staticRenderFns }