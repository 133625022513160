<template>
  <div>
    <div>
      <KTPortlet title="View assigned devices">
        <template slot="body">
          <Loader v-if="devices === null" :inContent="true">Loading...</Loader>
          <b-table-simple v-else responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Name</b-th>
                <b-th>Status</b-th>
                <b-th>Type</b-th>
                <b-th>Serial</b-th>
                <b-th>OS Version</b-th>
                <b-th>Firmware Version</b-th>
                <b-th>Assigned Template</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="device in devices" v-bind:key="device.id">
                <b-th sticky-column>{{ device.name }}</b-th>
                <b-td v-if="device.active === true">
                  <deviceState :device="device" />
                </b-td>
                <b-td v-else>
                  <v-chip
                    class="ma-2"
                    :style="`background: var(--danger); color: #fff;`"
                    >Device not active</v-chip
                  >
                </b-td>
                <b-td
                  >[{{ GetRouterType(device.routerTypeId).name }}]
                  {{ GetRouterType(device.routerTypeId).make }}
                  {{ GetRouterType(device.routerTypeId).model }}</b-td
                >
                <b-td>{{ device.serial }}</b-td>
                <b-td>{{ device.osVersion }}</b-td>
                <b-td>{{ device.firmwareVersion }}</b-td>
                <b-td v-if="GetTemplate(device.templateId) === null"
                  >Not linked to a template!</b-td
                >
                <b-td v-else>
                  <router-link
                    :to="`/Networks/${$route.params.serviceId}/Templates/${
                      GetTemplate(device.templateId).id
                    }`"
                  >
                    <b-button variant="default"
                      >{{ GetTemplate(device.templateId).name }}
                    </b-button>
                  </router-link>
                </b-td>
                <b-td>
                  <router-link
                    :to="`/Networks/${$route.params.serviceId}/Devices/${device.id}`"
                  >
                    <b-button variant="info">View/Edit</b-button>
                  </router-link>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import DEVICE_STATE from "./devices.state";

export default {
  components: {
    KTPortlet,
    Loader,
    deviceState: DEVICE_STATE,
  },
  watch: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      devices: null,
      types: null,
      templates: null,
    };
  },
  methods: {
    GetRouterType(typeId) {
      for (let tType of this.types) if (tType.id === typeId) return tType;

      return null;
    },
    GetTemplate(templateId) {
      for (let templ of this.templates) {
        if (templ.id === templateId) return templ;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BetterNetworks" },
      { title: "Devices" },
    ]);
    let self = this;
    TLib.getTypes(self, self.$route.params.serviceId)
      .then((typex) => {
        self.$data.types = typex;
        TLib.getTemplates(self, self.$route.params.serviceId)
          .then((templatex) => {
            self.$data.templates = templatex;
            TLib.getDevices(self, self.$route.params.serviceId)
              .then((data) => {
                self.$data.devices = data;
              })
              .catch((x) => {
                self.$log.error(x);
                //console.error("Error loading content");
              });
          })
          .catch((x) => {
            self.$log.error(x);
            //console.error("Error loading content");
          });
      })
      .catch((x) => {
        self.$log.error(x);
        //console.error("Error loading content");
      });
  },
};
</script>
