<template>
  <v-chip
    @click="canClickTools === false ? ()=>{} :
      $router.push(
        `/Networks/${$route.params.serviceId}/Tools/Ping?ip=${device.lastIP}&gateway=${device.gatewayId}&live=1&run=1`
      )
    "
    class="ma-2"
    :style="`cursor: ${canClickTools === false ? 'default' : 'pointer'}; background: var(--${
      !device.status || diffSecondsActual > deadPeriodLimit
        ? 'danger'
        : diffSecondsActual > periodLimit
        ? 'warning'
        : 'success'
    }); color: #fff;`"
  >
    Device
    {{
      !device.status || diffSecondsActual > periodLimit ? "Offline" : "Online"
    }}
    <span style="width: 5px"></span>
    <span v-if="diffSecondsActual <= periodLimit && device.status"></span>
    <span v-else-if="diffDays < longTimeAgoDayLimit">
      ({{
        `${diffDays > 0 ? `${diffDays}d ` : ""}` +
        `${
          diffHours > 0 || diffDays > 0
            ? `${lessThan(diffHours, 10) ? "0" : ""}${diffHours}:`
            : ""
        }` +
        `${
          diffMinutes > 0 || diffHours > 0 || diffDays > 0
            ? `${lessThan(diffMinutes, 10) ? "0" : ""}${diffMinutes}:`
            : ""
        }` +
        `${`${lessThan(diffSeconds, 10) ? "0" : ""}${diffSeconds}`}`
      }}{{ diffSecondsActual >= 60 ? "" : "s" }} ago)
    </span>
    <span v-else>(A long time ago)</span>
  </v-chip>
</template>

<script>
import MOMENT from "moment";

export default {
  props: ["device", 'canClickTools'],
  watch: {},
  data() {
    return {
      diffDays: 0,
      diffHours: 0,
      diffMinutes: 0,
      diffSeconds: 0,
      diffSecondsActual: 0,
      whatDo: -1,
      periodLimit: 61,//(60 * 1) + 59,
      deadPeriodLimit: (60 * 4) + 59,
      dayLimit: 1,
      longTimeAgoDayLimit: 7,
    };
  },
  methods: {
    lessThan(a, b) {
      return a < b;
    },
    DIFF(st) {
      return MOMENT().diff(MOMENT(this.lastInform), st);
    },
    REFRESH() {
      this.$data.diffDays = this.DIFF("days");
      this.$data.diffHours = this.DIFF("hours") - this.$data.diffDays * 24;
      this.$data.diffMinutes =
        this.DIFF("minutes") -
        (this.$data.diffDays * 24 * 60 + this.$data.diffHours * 60);
      this.$data.diffSecondsActual = this.DIFF("seconds");
      this.$data.diffSeconds =
        this.$data.diffSecondsActual -
        (this.$data.diffDays * 24 * 60 * 60 +
          this.$data.diffHours * 60 * 60 +
          this.$data.diffMinutes * 60);
      /*if (this.whatDo == -1) {
        this.$eventBus.on('cron-second', this.REFRESH);
        this.$data.whatDo = 0;
      } else if (this.$data.diffSecondsActual > this.periodLimit && this.whatDo == 0) {
        this.$eventBus.off('cron-second');
        this.$eventBus.on('cron-minute', this.REFRESH);
        this.$data.whatDo = 1;
      } else if (this.$data.diffSecondsActual <= this.periodLimit && this.whatDo == 1) {
        this.$eventBus.off('cron-minute');
        this.$eventBus.on('cron-second', this.REFRESH);
        this.$data.whatDo = 0;
      } */
    },
  },
  computed: {
    lastInform() {
      if (this.$tools.isNullOrUndefined(this.device.lastInform)) return 1;
      if (this.$tools.isString(this.device.lastInform))
        return Number.parseInt(this.device.lastInform);
      if (this.$tools.isNumber(this.device.lastInform))
        return this.device.lastInform;
      return 1;
    },
  },
  created() {
    this.REFRESH();
  },
  mounted() {
    this.$eventBus.on("cron-second", this.REFRESH);
  },
  beforeDispose() {
    this.$eventBus.off("cron-second");
    //this.$eventBus.off('cron-minute');
  },
};
</script>
